<template>
  <div
    id="IncentivesPanel"
    class="container-fluid bordered"
    v-if="incentives && incentives.incentive_categories"
  >
    <div id="IncentivesHeader" class="row text-center">
      <div class="col-12">
        <p class="subheader">
          We found
          <span class="accent">{{ incentives.total }}</span>
          {{ pluralize(incentives.total, "incentive") }} for you
          in <span class="accent">{{ state.name }}</span> that {{ pluralize(incentives.total, "offers", "offer") }} up to
          <span class="accent">{{
            toCurrency(incentives.max_amount)
          }}</span> in savings
        </p>
        <div class="mb-2"><img class="car" src="~Images/car.svg" /></div>
        <h3>Tax Credits, Rebates &amp; Perks</h3>
      </div>
    </div>
      <IncentiveCategory
        :incentiveCategory="category"
        v-for="(category, idx) in incentives.incentive_categories"
        :key="idx"
      />
  </div>
</template>

<script>
const IncentiveCategory = () =>
  import("Components/Incentives/IncentiveCategory");
import common from "Mixins/common";

export default {
  props: ['incentives', 'state'],
  components: {
    IncentiveCategory,
  },
  mixins: [common],
};
</script>

<style lang="scss" scoped>
#IncentivesPanel {
  padding: 25px 30px 25px 30px;
  background-color: #1d7fda;
  border: 17px black solid;
}

#IncentivesHeader {
  h3 {
    font-family: $primary-header-font-family;
    font-weight: normal;
  }
  h3 {
    color: #0c3b69;
    font-size: 1.44rem;
    margin-bottom: 22px;
  }

  p.subheader {
    padding-top: 0;
    margin-bottom: 10px;
    color: #333333;
    font-size: 0.75rem;
    font-family: $primary-header-font-family;
  }

  img.car {
    padding-top: 0;
  }

  .accent {
    color: #a9d6ff;
    font-weight: bold;
  }
}
</style>